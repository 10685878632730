.about {
  display: flex;
  color: white;
  justify-content: center;

}

.about-container {
  padding-bottom: 50px;
}

.skill_header {
  padding-top: 75px;
  padding-bottom: 20px;
  margin: 0 auto;
}

.about_desc {
  display: flex;
  margin: 0 auto;
  text-align: center;
}

.about_card {
  text-align: center;
}

.card {
  margin: 20px;
  font-size: 20px;
  max-width: 200px;
}
