body {
  background: black;
}

.projects {
  border-bottom: 2px solid gray;
  color: white;
  padding: 1em;
  font-size: 24px;
}

.projectbtn {
  display: flex;
}

.project_header {
  margin-top: 100px;
  margin-bottom: 20px;
}


.btn-site {
    border: solid 2px #4fe8fc;
    padding: 8px;
    border-radius: 16px;
    padding-left: 20px;
    padding-right: 20px;
    color: #4fe8fc;
    text-decoration: none;
    margin-top: 5px;
    margin-bottom: 10px;
}

.btn-site:hover {
  border: solid 2px #fc9c38;
  color: #fc9c38;
  text-decoration: none;
}

h2 {
  color: #fc9c38;
}

.code {
  width: 150px;
  margin-bottom: 10px;
}

h1 {
  font-size: 36px;
  color: white;
  margin-top: 10px;
}

.container {
  background-color: black;
  padding-left: 4em;
  padding-right: 4em;
  margin: 0px 20px 20px 20px;
}

.carousel {
  color: white;
}

.proimg {
  max-width: 500px;
  display: flex;
  float: left;
  padding-right: 20px;
}

.project_description {
  padding-right: 20px;
}

.project_text {
  display: flex;
}
